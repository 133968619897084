// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC1u5b8csmGHVqYyZSWqadh5AKZDlfTo2E",
  authDomain: "gasbilldknew1.firebaseapp.com",
  databaseURL: "https://gasbilldknew1-default-rtdb.firebaseio.com",
  projectId: "gasbilldknew1",
  storageBucket: "gasbilldknew1.appspot.com",
  messagingSenderId: "184240123287",
  appId: "1:184240123287:web:97c52b7bebaaba9d74a8d2",
  measurementId: "G-BTG5BK72Z0"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };